import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/Layout' 
import ArticleCard from "../components/article/card"
import Seo from '../components/Seo'
import ServicesGrid from "../components/servicesGrid"
import Blog from "../components/sections/blog"
const UserTemplate = ({ data }) => (
    <Layout>
      <Seo
        title={"Todos los articulos de "+data.strapiCategories.name+" - Cantabria Digital, Diseño Web"} 
        description={"Todos los articulos de "+data.strapiCategories.name+" - Cantabria Digital, Diseño Web"} 
      />
      <h1 className="text-2xl font-extrabold text-center py-8">Todos los artículos de {data.strapiCategories.name}</h1>
      <div className="mx-auto container grid grid-cols-3 gap-4 my-8">
        {data.allStrapiArticles.edges.map(function(article,index){
          return(
            <ArticleCard data={article} picture={true}/>
          )   
        })}   
      </div>  
      <ServicesGrid/>
      <Blog/>
    </Layout>
  )
  
export default UserTemplate

export const query = graphql`query asd($id: String!) {
  strapiCategories(name: {eq: $id}) {
    id
    name
  }
  allStrapiArticles(
    sort: {fields: published_at, order: ASC}
    filter: {categories: {elemMatch: {name: {eq: $id}}}}
  ) {
    edges {
      article: node {
        title
        description
        content
        image {
          localFile{
            childImageSharp {
              gatsbyImageData(width: 960, layout: CONSTRAINED)
            }
          }
        }
        author {
          id
          username
        }
        categories {
          name
        }
      }
    }
  }
}
`